import { BrowserName } from "../constant/BrowserName"

export const detectBrowser = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if( userAgent.includes( 'msie' ) || userAgent.includes( 'trident' ) ) {
        return ( BrowserName.IE )
    } else if( userAgent.includes( 'edge' ) ) {
        return ( BrowserName.EDGE )
    } else if( userAgent.includes( 'chrome' ) ) {
        return ( BrowserName.CHROME )
    } else if( userAgent.includes( 'safari' ) ) {
        return ( BrowserName.SAFARI )
    } else if( userAgent.includes( 'firefox' ) ) {
        return ( BrowserName.FIREFOX )
    } else {
        return ( BrowserName.OTHER_BROWSER )
    }
}
