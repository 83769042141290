// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@ffm... Remove this comment to see the full error message
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import { detectBrowser } from "./detectBrowser"
import { BrowserName } from "../constant/BrowserName"
import { isWebView } from "./isWebView"
import { isSmartPhone } from "./detectDevice"
import { hasAllHalfWidthLetters } from "./hasAllHalfWidthLetters"
import { store } from "../state/ReduxWrapper"

const ffmpeg = createFFmpeg( { log: true } );
const TrimBuffer = 0.3

class FFmpeg {
    loadEnd: boolean;
    previousTrimVideoFileName: string;
    trimCount: number;
    videoTrimming: boolean;

    constructor () {
        this.loadEnd = false
        this.videoTrimming = false
        this.trimCount = 0
        this.previousTrimVideoFileName = ""
        this.isAuthenticated() && this.init()
    }

    getCanUseFFmpeg = () => {
        const thisBrowser = detectBrowser()
        console.log( "this browser is ", thisBrowser )
        const canUseFFmpeg = thisBrowser === BrowserName.CHROME && !isWebView() && !isSmartPhone()
        console.log( "can use ffmpeg ", canUseFFmpeg )
        return canUseFFmpeg
    }

    isAuthenticated = () => {
        const { isLoggedIn } = store.getState().app
        return isLoggedIn
    }


    init = () => this.getCanUseFFmpeg() && this.load()

    load = async () => {
        console.log( "ffmpeg load start" )
        if ( this.loadEnd ) return
        await ffmpeg.load()
        console.log( "ffmpeg load end" )
        this.loadEnd = true
    }

    trim = async ( {
        startTime,
        endTime,
        file,
        trimWithBuffer = true
    }: {
        startTime: number,
        endTime: number,
        file: File,
        trimWithBuffer?: boolean
    } ) => {

        if ( !this.validateBeforeTrim( file ) ) {
            return false
        }

        // 前のを破棄
        this.disposePreviousFile( file )

        await ffmpeg.write(
            file.name,
            file
        );
        const outputFileName = this.getOutputFileName( file )

        const trimStartTime = trimWithBuffer ?
            this.getTrimStartTimeWithBuffer( startTime ) :
            startTime

        await this.runTrim( {
            trimStartTime,
            endTime,
            file,
            outputFileName
        } )

        console.log( "t", trimStartTime, endTime )

        ffmpeg.remove( file.name )
        this.trimCount += 1

        const data = ffmpeg.read( outputFileName )
        return data
    }

    validateBeforeTrim = ( file: File ) => {
        if ( !this.loadEnd ) {
            alert( "ロード中です" )
            return false
        }
        if ( this.videoTrimming ) {
            alert( "既にトリミング中です" )
            return false
        }

        if ( !hasAllHalfWidthLetters( file.name ) ) {
            alert( "ファイル名に全角が入っているため、処理できません" )
            return false
        }
        return true
    }

    disposePreviousFile = ( file: any ) => {
        file.name !== this.previousTrimVideoFileName &&
            this.previousTrimVideoFileName &&
            ffmpeg.remove( this.previousTrimVideoFileName )
    }

    getTrimStartTimeWithBuffer = ( startTime: number ) => {
        const trimStartTime = startTime - TrimBuffer
        if ( trimStartTime < 0 ) return 0
        else return trimStartTime
    }

    runTrim = async ( {
        trimStartTime,
        endTime,
        file,
        outputFileName
    }: any ) => {
        trimStartTime ?
            // await ffmpeg.trim( file.name, outputFileName, trimStartTime, endTime ) :
            // await ffmpeg.run( `-ss ${ trimStartTime } -i ${ file.name }  -t ${ trimStartTime + endTime } -c copy ${ outputFileName }` ) :
            // await ffmpeg.run( `-ss ${ trimStartTime } -i ${ file.name }  -t ${ trimStartTime + endTime } -c copy ${ outputFileName }` ) :
            // await ffmpeg.run( `-i ${ file.name } -ss ${ trimStartTime } -t ${ trimStartTime + endTime } -c copy ${ outputFileName }` ) :
            // await ffmpeg.run( ` -ss ${ trimStartTime } -i ${ file.name } -to ${ endTime } -c:v copy -c:a copy ${ outputFileName }` ) : 
            await ffmpeg.run( `-i ${ file.name }  -ss ${ trimStartTime } -to ${ endTime } -c copy ${ outputFileName }` ) :
            // await ffmpeg.run( `-i ${ file.name } -ss ${ trimStartTime } -to ${ endTime } -c copy ${ outputFileName }` ) : // base
            await ffmpeg.run( `-i ${ file.name }  -t ${ endTime } -c copy ${ outputFileName }` )

    }

    getOutputFileName = ( file: any ) => {
        const extentionName = file.name.split( "." ).slice( -1 )[ 0 ]
        const fileBodyName = file.name.split( "." ).slice( 0, -1 )
        const outputFileName = fileBodyName + String( this.trimCount ) + "." + extentionName
        return outputFileName
    }

    read = ( fileName: string ) => {
        const data = ffmpeg.read( fileName );
        return data
    }
}

export default new FFmpeg()
